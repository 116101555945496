<template>
  <!-- send-invoice-modal -->
  <b-modal
    id="add-account-modal"
    ref="AddAccountModal"
    title="Add an Account"
    size="md"
    header-class="font-primary"
    header-bg-variant="light"
    header-text-variant="main-green"
    header-border-variant="white"
    footer-bg-variant="none"
    footer-border-variant="white"
    @hide="closeModal()"
  >
    <template v-slot:modal-title>
      <span class="text-main-green">{{
        updateAccount != null ? "Update Account" : "Add an Account"
      }}</span>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <div
        class="d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-end mx-0 my-0 w-100"
      >
        <b-button
          variant="gray-550"
          pill
          class="border-0 bg-gray-550 text-white action-btn"
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
          variant="main-green"
          pill
          class="mt-2 mt-sm-0 ml-0 ml-sm-2 border-0 action-btn"
          @click="saveAccount"
        >
          Save
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-dark"
        @click="closeModal()"
        aria-label="Close Modal"
        font-scale="0.8"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 my-3 my-md-2 px-1 py-1"
      no-gutters
    >
      <!-- full row input (Account name) -->
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100"
        no-gutters
      >
        <b-col cols="12" sm="4">
          <span class="text-left text-dark font-primary font-weight-normal"
            >Category Name</span
          >
        </b-col>
        <b-col cols="8" class="mt-2 mt-sm-0">
          <FormInput
            groupId="user-name-group"
            id="user-name-group-input"
            class="text-prime-gray rounded-2"
            :state="accountNameError.status"
            :invalidFeedback="`${accountNameError.message}`"
            v-model="accountName"
            isRequired
            :whiteBG="false"
            trim
            form="login-form"
            type="text"
          ></FormInput>
        </b-col>
      </b-row>
      <!-- full row input (Account type) -->
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100"
        no-gutters
      >
        <b-col cols="12" sm="4">
          <span class="text-left text-dark font-primary font-weight-normal"
            >Account type</span
          >
        </b-col>
        <b-col v-if="!updateAccount" cols="8" class="mt-2 mt-sm-0">
          <FormSelect
            groupId="fiscalyear-group"
            id="fiscalyear-group-input"
            class="text-prime-gray rounded-2"
            :state="accountTypeError.status"
            :invalidFeedback="`${accountTypeError.message}`"
            v-model="accountType"
            :whiteBG="false"
            :options="accountTypeOptions"
            form="login-form"
          ></FormSelect>
        </b-col>
        <b-col v-else cols="8" class="mt-2 mt-sm-0">
          <FormInput
            :isDisabled="updateAccount != null"
            groupId="fiscalyear-group"
            id="fiscalyear-group-input"
            class="text-prime-gray rounded-2"
            :state="accountTypeError.status"
            :invalidFeedback="`${accountTypeError.message}`"
            v-model="accountType"
            :whiteBG="false"
            :options="accountTypeOptions"
            form="login-form"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100"
        no-gutters
      >
        <b-col cols="12" sm="4">
          <span class="text-left text-dark font-primary font-weight-normal"
            >GL Code</span
          >
        </b-col>
        <b-col cols="8" class="mt-2 mt-sm-0">
          <FormInput
            groupId="user-name-group"
            id="user-name-group-input"
            class="text-prime-gray rounded-2"
            :state="glCodeError.status"
            :invalidFeedback="`${glCodeError.message}`"
            v-model="glCode"
            isRequired
            :whiteBG="false"
            trim
            form="login-form"
            type="text"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100"
        no-gutters
      >
        <b-col cols="12" sm="4">
          <span class="text-left text-dark font-primary font-weight-normal"
            >GL Code Description</span
          >
        </b-col>
        <b-col cols="8" class="mt-2 mt-sm-0">
          <FormInput
            groupId="user-name-group"
            id="user-name-group-input"
            class="text-prime-gray rounded-2"
            :state="glCodeDescError.status"
            :invalidFeedback="`${glCodeDescError.message}`"
            v-model="glCodeDesc"
            isRequired
            :whiteBG="false"
            trim
            form="login-form"
            type="text"
          ></FormInput>
        </b-col>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100"
        no-gutters
      >
        <b-col cols="12" sm="4">
          <span class="text-left text-dark font-primary font-weight-normal"
            >Archive this account</span
          >
        </b-col>
        <b-col cols="4" class="mt-2 mt-sm-0">
          <b-form-checkbox
            id="checkbox-1"
            v-model="archive"
            name="archive checkbox"
            :value="true"
            :unchecked-value="false"
          >
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!-- full row input (Account ID) -->
      <!--      <b-row-->
      <!--        class="d-flex flex-row align-items-start justify-content-start w-100"-->
      <!--        no-gutters-->
      <!--      >-->
      <!--        <b-col cols="12" sm="4">-->
      <!--          <span class="text-left text-dark font-primary font-weight-normal"-->
      <!--            >Account ID</span-->
      <!--          >-->
      <!--        </b-col>-->
      <!--        <b-col cols="8" class="mt-2 mt-sm-0">-->
      <!--          <FormInput-->
      <!--            groupId="user-name-group"-->
      <!--            id="user-name-group-input"-->
      <!--            class="text-prime-gray rounded-2"-->
      <!--            v-model="accountCode"-->
      <!--            :state="accountCodeError.status"-->
      <!--            :invalidFeedback="accountCodeError.message"-->
      <!--            isRequired-->
      <!--            :whiteBG="false"-->
      <!--            trim-->
      <!--            form="login-form"-->
      <!--            type="text"-->
      <!--          ></FormInput>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
      <!-- full row input (Description) -->
      <!--      <b-row-->
      <!--        class="d-flex flex-row align-items-start justify-content-start w-100"-->
      <!--        no-gutters-->
      <!--      >-->
      <!--        <b-col cols="12" sm="4">-->
      <!--          <span class="text-left text-dark font-primary font-weight-normal"-->
      <!--            >Description</span-->
      <!--          >-->
      <!--        </b-col>-->
      <!--        <b-col cols="12" sm="8" class="mt-2 mt-sm-0">-->
      <!--          <FormInput-->
      <!--            groupId="user-name-group"-->
      <!--            id="user-name-group-input"-->
      <!--            class="text-prime-gray rounded-2"-->
      <!--            v-model="description"-->
      <!--            :state="descriptionError.status"-->
      <!--            :invalidFeedback="descriptionError.message"-->
      <!--            isRequired-->
      <!--            isTextArea-->
      <!--            :whiteBG="false"-->
      <!--            trim-->
      <!--            form="login-form"-->
      <!--            type="text"-->
      <!--          ></FormInput>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
    </b-row>
  </b-modal>
</template>

<script>
// components
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import getActiveGlCodeList from "@/util/FetchAllGlCode";

// services
import {
  AddChartOfAccount,
  UpdateChartOfAccount,
} from "@/services/transactions.service";

export default {
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    accounts: {
      type: Object,
    },
    updateAccount: {
      type: Object,
    },
    selectedAccountType: {
      type: Object,
    },
    AccountType: {
      type: String,
    },
  },
  name: "AddAccountModal",
  components: {
    FormInput,
    FormSelect,
  },
  data() {
    return {
      archive: false,
      glCodeList: [],
      accountName: null,
      accountId: null,
      accountCode: null,
      description: null,
      accountType: null,
      glCodeDesc: null,
      glCode: null,
      Type: null,
      accountTypeError: {
        status: null,
        message: "",
      },
      accountNameError: {
        status: null,
        message: "",
      },
      accountCodeError: {
        status: null,
        message: "",
      },
      descriptionError: {
        status: null,
        message: "",
      },
      glCodeDescError: {
        status: null,
        message: "",
      },
      glCodeError: {
        status: null,
        message: "",
      },
      // accountTypeOptions: ["Cash and bank", "Money in Transit", "Expected payments from customers", "Inventory", "Property, Plant, Equipment", "Depreciation and Amortization", "Vendor Prepayments and Vendor Credits", "Other Short-Term Asset", "Other Long-Term Asset"],
      accountTypeOptions: [],
      paymentMethodOptions: ["Cash payment", "Card payment", "Bank transfers"],
    };
  },
  watch: {
    selectedAccountType(val) {
      if (val) this.accountType = val;
      else this.accountType = null;
    },
    AccountType(val) {
      this.Type = val;
      this.UpdateAccounts(this.accounts, val);
    },
    accountType(value) {
      if (value == null) {
        this.accountTypeError.status = null;
      } else if (value) {
        this.accountTypeError.status = true;
      } else {
        this.accountTypeError.status = false;
        this.accountTypeError.message = "Please fill out this field";
      }
    },
    accountName(value) {
      if (value == null) {
        this.accountNameError.status = null;
      } else if (!value) {
        this.accountNameError.status = false;
        this.accountNameError.message = "Please fill out this field";
      } else if (!value.match(/^\w+( )?( +\w+( )?)*$/)) {
        this.accountNameError.status = false;
        this.accountNameError.message =
          "Account name must not contain invalid characters";
      } else this.accountNameError.status = true;
    },
    updateAccount(val) {
      if (val) {
        this.accountId = val.id;
        this.accountCode = val.code;
        this.accountName = val.title;
        this.description = val.description;
        this.glCode = val.gl_code;
        this.glCodeDesc = val.gl_code_description;
        this.archive = val.archived;
        // this.accountType = {
        //   type: this.capitalizeFirstLetter(val.path.split(",")[0]),
        //   subcategory: this.capitalizeFirstLetter(val.path.split(",")[1]),
        // };
        this.accountType = this.capitalizeFirstLetter(val.path.split(",")[1]);
        // path: "assets,Cash and Bank,wewewewe2323"
        (this.accountType = this.capitalizeFirstLetter(val.path.split(",")[1])),
          console.log(this.accountTypeOptions);
      } else {
        this.accountId = null;
        this.accountCode = null;
        this.accountName = null;
        this.description = null;
        this.accountType = null;
        this.glCode = null;
        this.glCodeDesc = null;
      }
    },
    accounts(val) {
      this.UpdateAccounts(val, this.Type);
    },
    accountCode(value) {
      if (value == null) {
        this.accountCodeError.status = null;
      } else if (value) {
        this.accountCodeError.status = true;
      } else {
        this.accountCodeError.status = false;
        this.accountCodeError.message = "Please fill out this field";
      }
    },
    description(value) {
      if (value == null) {
        this.descriptionError.status = null;
      } else if (value) {
        this.descriptionError.status = true;
      } else {
        this.descriptionError.status = false;
        this.descriptionError.message = "Please fill out this field";
      }
    },
    glCode(value) {
      if (value == null) {
        this.glCodeError.status = null;
      } else if (value) {
        this.glCodeError.status = true;
        if (!(this.updateAccount?.gl_code === value)) {
          if (this.glCodeList.includes(value)) {
            this.glCodeError.status = false;
            this.glCodeError.message = "Please use different Gl Code";
          }
        }
      } else {
        this.glCodeError.status = false;
        this.glCodeError.message = "Please fill out this field";
      }
    },
    glCodeDesc(value) {
      if (value == null) {
        this.glCodeDescError.status = null;
      } else if (value) {
        this.glCodeDescError.status = true;
      } else {
        this.glCodeDescError.status = false;
        this.glCodeDescError.message = "Please fill out this field";
      }
    },
  },
  async mounted() {
    this.glCodeList = await getActiveGlCodeList();
  },
  methods: {
    async saveAccount() {
      if (this.validateForm().length === 0) {
        if (!(this.updateAccount?.gl_code === this.glCode)) {
          if (this.glCodeList.includes(this.glCode)) {
            this.glCodeError.status = false;
            this.glCodeError.message = "GL Code Already Used";
            let payloadNotify = {
              isToast: true,
              title: "GlCode Already Used",
              content:
                "This Gl Code already using in another account please use different one",
              variant: "danger",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            return;
          }
        }
        try {
          if (this.updateAccount) {
            // update
            let payload = {
              name: this.accountName,
              code: this.accountCode,
              description: this.description,
              gl_code: this.glCode,
              gl_code_description: this.glCodeDesc,
              archived: this.archive,
            };
            let { data } = await UpdateChartOfAccount(payload, this.accountId);
            this.$emit("reloadFn");
            console.log("update account", data);
            let payloadNotify = {
              isToast: true,
              title: "Account updated",
              content: "The account has been updated successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            console.log("notify", payloadNotify);
            this.$bvModal.hide("add-account-modal");
          } else {
            let payload = {
              type: this.accountType.type.toLowerCase(),
              subcategory: this.accountType.subcategory,
              name: this.accountName,
              code: this.accountCode,
              description: this.description,
              gl_code: this.glCode,
              gl_code_description: this.glCodeDesc,
              archived: this.archive,
            };
            let { data } = await AddChartOfAccount(payload);
            this.$emit("reloadFn");
            console.log("add account", data);
            let payloadNotify = {
              isToast: true,
              title: "Account Added",
              content: "The account has been added successfully",
              variant: "success",
            };
            this.$store.dispatch("notification/setNotify", payloadNotify);
            console.log("notify", payloadNotify);
            this.resetFn();
            this.$bvModal.hide("add-account-modal");
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    validateForm() {
      var errors = [];
      if (!this.accountType) {
        this.accountTypeError.status = false;
        this.accountTypeError.message = "Please fill out this field";
        errors.push("Please fill out this field");
      }
      if (!this.accountName) {
        this.accountNameError.status = false;
        this.accountNameError.message = "Please fill out this field";
        errors.push("Please fill out this field");
      } else if (!this.accountName.match(/^\w+( )?( +\w+( )?)*$/)) {
        this.accountNameError.status = false;
        this.accountNameError.message =
          "Account name must not contain invalid characters";
        errors.push("Account name must not contain invalid characters");
      }
      if (!this.glCode || this.glCode.length < 1) {
        this.glCodeError.status = false;
        this.glCodeError.message = "GL Code can not be empty!";
        let payloadNotify = {
          isToast: true,
          title: "GL Code can not be empty!",
          content: "GL Code is required for all accounts!",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
        errors.push("Empty GL Code!");
      }
      return errors;
    },

    closeModal() {
      this.resetFn();
      this.$emit("onClose");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    resetFn() {
      this.accountId = null;
      this.accountCode = null;
      this.accountName = null;
      this.description = null;
      this.accountType = null;
      this.glCode = null;
      this.glCodeDesc = null;
      this.archive = false;
    },
    UpdateAccounts(accounts, type) {
      let accountsArray = [];
      // console.log("accountsArray", val);
      for (const account in accounts) {
        const label = accounts[account].title;
        const options = [];
        for (const sub in accounts[account].sub) {
          options.push({
            value: {
              subcategory: accounts[account].sub[sub].title,
              type: accounts[account].title,
            },
            text: accounts[account].sub[sub].title,
          });
        }
        if (type === label || this.AccountType === "All") {
          accountsArray.push({
            label,
            options,
          });
        }
      }
      this.accountTypeOptions = accountsArray;
    },
  },
};
</script>

<style scoped>
.tab-wrapper {
  border-bottom: 2px solid var(--gray-750);
  /* border-bottom: none; */
}

.customer-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
}

.customer-tabs >>> .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}

.action-btn {
  height: auto;
  min-width: 120px;
}

.action-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .action-btn {
    width: 100%;
  }
}
</style>
